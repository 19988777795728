<template>
  <section id="hero-select" class="game--stage">
    <transition name="preloader-transition">
      <preloader v-if="preload" :assets="assets"></preloader>
    </transition>
    <div v-if="!preload" class="wrapper">
      <div id="hero-select--swiper">
        <div class="bg hero-select-bg" :style="{transform: 'translateX(' + bgTransition + '%)' }"><img src="/assets/hero-select-bg.jpg"></div>
        <div class="swiper-container" style="height: 100%">
          <div class="swiper-wrapper" style="height: 100%">
            <div v-for="hero in appData.heroes" :key="hero.id" class="swiper-slide hero-select--slide">
              <div style="width: 100%; position: relative; height: 100%">
                <div style="display: table; width: 100%; height: 100%">
                  <div style="display: table-row">
                    <div style="display: table-cell">
                      <div class="hero-select--title">
                        <span>{{ hero.title }}</span>
                      </div>
                    </div>
                  </div>
                  <div style="display: table-row; height: 100%">
                    <div style="display: table-cell">
                      <div class="hero-select--image">
                        <img :src="hero.image">
                      </div>
                    </div>
                  </div>
                  <div style="display: table-row">
                    <div style="display: table-cell">
                      <div class="hero-select--text" v-html="hero.description"></div>
                      <div style="margin-top: 30px;">
                        <button class="btn btn-1" @click="showHeroProfile(hero)">
                          <span>Це мій герой</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper--navigation--button swiper--next"></div>
        <div class="swiper--navigation--button swiper--prev disabled"></div>
      </div>
    </div>
    <transition name="popup-transition">
      <div v-if="profile" class="wrapper">
        <div class="bg"><img src="/assets/hero-select-bg.jpg"></div>
        <div class="container">
          <div class="hero--profile--image">
            <img :src="profile.profile_image" style="object-fit: cover; object-position: top center;">
          </div>
          <div class="hero--profile--description"
               style="padding-top: 50px; height: 50%;position: absolute;bottom: 0;left: 0;width: 100%;display: flex;align-items: center;background: linear-gradient(180deg, rgba(10, 10, 85, 0) 0%, #0A0A55 18.75%, #5A00B4 100%);">
            <div style="margin: auto; width: 100%; padding: 0 20px">
              <div class="hero-select--title" style="margin-top: 30px; margin-bottom: 30px">
                <span>{{ profile.title }}</span>
              </div>
              <div class="hero-select--text">
                <p>Переходимо до тренування?
                </p></div>
              <div style="display: flex; margin-top: 30px; align-items: center">
                <button class="btn btn-2 btn-small btn-small-font" @click="closeProfile()"><span>Обрати<br>іншого героя</span>
                </button>
                <button class="btn btn-3 btn-small" @click="selectHero(profile)"><span>Так</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import Swiper from 'swiper';
import Preloader from "@/components/Preloader";

export default {
  name: 'hero-select-stage',
  components: { Preloader },
  props: ['appData'],
  data() {

    return {
      preload: true,
      assets: [
        '/assets/hero-select-bg.jpg',
        '/assets/hero-1.png',
        '/assets/hero-2.png',
        '/assets/hero-3.png?v=1',
        '/assets/hero-4.png',
        '/assets/hero-profile-1.png',
        '/assets/hero-profile-2.png',
        '/assets/hero-profile-3.png',
        '/assets/hero-profile-4.png',
        '/assets/fight-hero-1.png',
        '/assets/fight-hero-2.png',
        '/assets/fight-hero-3.png',
        '/assets/fight-hero-4.png',
      ],
      selectedHero: null,
      bgStep: 0,
      bgTransition: 0,
      profile: null
    }
  },
  watch: {
    bgStep: function(value){
      this.bgTransition = -value*10;
    },
    preload: function(value){
      if(value === false){
        this.$nextTick(function(){
          this.init();
        });
      }
    }
  },
  mounted() {
  },
  methods: {
    init() {

      let self = this;

      let node = self.$el;
      let swiperNode = node.querySelector('#hero-select--swiper');
      let swiperContainer = swiperNode.querySelector('.swiper-container');
      let nextButton = swiperNode.querySelector('.swiper--next');
      let prevButton = swiperNode.querySelector('.swiper--prev');

      swiperNode.swiperInstance = new Swiper(swiperContainer, {
        spaceBetween: 0,
        speed: 600,
        on: {
          init: function (e) {
            if (e.realIndex === e.slides.length - 1) {
              nextButton.classList.add('disabled');
            } else {
              nextButton.classList.remove('disabled');
            }

            if (e.realIndex > 0) {
              prevButton.classList.remove('disabled');
            } else {
              prevButton.classList.add('disabled');
            }
          },
          slideChange: function (e) {

            if (e.realIndex > 0) {
              prevButton.classList.remove('disabled');
            } else {
              prevButton.classList.add('disabled');
            }

            if (e.realIndex === e.slides.length - 1) {
              nextButton.classList.add('disabled');
            } else {
              nextButton.classList.remove('disabled');
            }

            self.bgStep = e.realIndex;
          }
        }
      });

      nextButton.addEventListener('click', function () {
        swiperNode.swiperInstance.slideNext();
      });

      prevButton.addEventListener('click', function () {
        swiperNode.swiperInstance.slidePrev();
      });

      console.log('inited');
    },
    showHeroProfile(hero) {

      this.profile = hero;
    },
    selectHero(profile) {

      this.appData.gameState.hero = profile;

      this.appData.stage = 'training-stage';
    },
    closeProfile(){

      this.profile = null;
    }
  }
}
</script>