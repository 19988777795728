<template>
  <section class="game--stage">
    <div class="wrapper">
      <div id="training--swiper" :data-step="bgStep">
        <div class="bg training-bg">
          <img src="/assets/training-bg.jpg">
        </div>
        <div class="bg myst-1">
          <img src="/assets/myst-1.jpg">
        </div>
        <div class="bg myst-2">
          <img src="/assets/myst-2.jpg">
        </div>
        <div class="swiper-container" style="height: 100%">
          <div class="swiper-wrapper" style="height: 100%">

            <div class="swiper-slide training--slide">
              <div class="bg"><img src="/assets/main-bg.jpg"></div>
              <div style="position: relative; width: 100%">
                <div class="training--title"><span>ТРЕНУВАННЯ</span></div>
                <div class="training--text">
                  <p>Підготовка відкриє шлях до Арени міфів, де чекає на тебе битва за право зрівнятися з богами.</p>
                  <p>Запам’ятовуй усе, що побачиш!</p>
                </div>
              </div>
            </div>
            <div v-for="(training,index) in appData.trainings" :key="'training_slide' + index" class="swiper-slide training--slide">
              <div class="training--description">
                <div style="display: table; height: 100%; width: 100%">
                  <div style="display: table-row">
                    <div style="display: table-cell">
                      <div class="frame--top"></div>
                    </div>
                  </div>
                  <div style="display: table-row">
                    <div style="display: table-cell">
                      <div class="training--step">{{ index+1 }} / 6</div>
                    </div>
                  </div>
                  <div style="display: table-row; height: 100%">
                    <div style="display: table-cell; vertical-align: middle; padding: 3rem 0;">
                      <div class="training--text" v-html="training"></div>
                    </div>
                  </div>
                  <div style="display: table-row">
                    <div style="display: table-cell">
                      <div class="frame--bottom"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide training--slide">
              <div style="width: 100%; height: 100%; position: relative">
                <div style="position: absolute; width: 100%; bottom: 19.8%; left: 0; text-align: center;">
                  <button class="btn btn-1" @click="startRegularFight()"><span>Розпочати битву</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper--navigation--button swiper--next"></div>
        <div class="swiper--navigation--button swiper--prev disabled"></div>
      </div>
    </div>
  </section>

</template>

<script>
import Swiper from 'swiper';
import SwiperCore, {
  EffectFade,
} from 'swiper/core';
SwiperCore.use([EffectFade]);

export default{
  name: 'training-stage',
  props: ['appData'],
  data(){

    return {
      bgStep: 0
    }
  },
  mounted(){

    this.init();
  },
  methods: {

    init(){
      let self = this;

      let node = self.$el;
      let swiperNode = node.querySelector('#training--swiper');
      let swiperContainer = swiperNode.querySelector('.swiper-container');
      let nextButton = swiperNode.querySelector('.swiper--next');
      let prevButton = swiperNode.querySelector('.swiper--prev');

      swiperNode.swiperInstance = new Swiper(swiperContainer, {
        spaceBetween: 0,
        speed: 600,
        effect: 'fade',
        fadeEffect: 'crossFade',
        on: {
          init: function(e){
            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }
          },
          slideChange: function(e){

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }

            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            self.bgStep = e.realIndex;
          }
        }
      });

      nextButton.addEventListener('click', function(){
        swiperNode.swiperInstance.slideNext();
      });

      prevButton.addEventListener('click', function(){
        swiperNode.swiperInstance.slidePrev();
      });
    },
    startRegularFight(){

      this.appData.stage = 'regular-fight-stage';
    }
  }
}
</script>