import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        getById(data, id){

            for(let obj of data){

                if(obj.id == id){

                    return obj;
                }
            }

            return null;
        }
    }
})

window.appData = {
    stage: 'home-stage',
    // gameState: {
    //     hero: {
    //         "id": 1,
    //         "title": "PONTAS",
    //         "description": "<p>Морський воїн із характером мінливим, як хвилі. У будь-якій компанії він наче риба у воді. Але варто розгнівати Понтаса — і його тризубець влаштує справжній буревій.</p>",
    //         "image": "/assets/hero-1.png",
    //         "profile_image": "/assets/hero-profile-1.png",
    //         "fight_image": "/assets/fight-hero-1.png",
    //         "victory_image": "/assets/finish-hero-1.png"
    //     },
    //     progress: [1,2,3,4,5,6,7,8,9,11]
    // },
    gameState: {
        hero: null,
        progress: []
    },
    device: {
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: window.innerWidth / window.innerHeight >= 1 ? 'landscape' : 'portrait'
    },
    heroes: [
        {
            "id": 1,
            "title": "PONTAS",
            "description": "<p>Морський воїн із характером мінливим, як хвилі. У будь-якій компанії він наче риба у воді. Але варто розгнівати Понтаса — і його тризубець влаштує справжній буревій.</p>",
            "image": "/assets/hero-1.png",
            "profile_image": "/assets/hero-profile-1.png",
            "fight_image": "/assets/fight-hero-1.png",
            "victory_image": "/assets/finish-hero-1.png"
        },
        {
            "id": 2,
            "title": "SELENA",
            "description": "<p>Сяйна німфа, яка народилася раніше за Місяць. Веде переважно нічний спосіб життя. Їй підвладні всі зорі, а її чарівний факел знищує ворогів місячним вогнем.</p>",
            "image": "/assets/hero-2.png",
            "profile_image": "/assets/hero-profile-2.png",
            "fight_image": "/assets/fight-hero-2.png",
            "victory_image": "/assets/finish-hero-2.png"
        },
        {
            "id": 3,
            "title": "ETHER",
            "description": "<p>Невловимий дух вітру. Ніхто так і не бачив обличчя Ефіра, але всі знають про його руйнівну силу й уміння проникнути куди заманеться.</p>",
            "image": "/assets/hero-3.png",
            "profile_image": "/assets/hero-profile-3.png",
            "fight_image": "/assets/fight-hero-3.png",
            "victory_image": "/assets/finish-hero-3.png"
        },
        {
            "id": 4,
            "title": "BRONT",
            "description": "<p>Загадкове створіння, яке, за легендами, походить від циклопів, але ці чутки спростовує. Характер — вибуховий, як вогняний молот із пекельної кузні.</p>",
            "image": "/assets/hero-4.png",
            "profile_image": "/assets/hero-profile-4.png",
            "fight_image": "/assets/fight-hero-4.png",
            "victory_image": "/assets/finish-hero-4.png"
        }
    ],
    staffs: [
        {
            "id": 1,
            "title": "Ліра просвітлення",
            "text": "розвіює чутки своїм звучанням",
            "image": "/assets/fight-staff-1.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 2,
            "title": "Вогонь знання",
            "text": "запалює світло в темряві невігластва",
            "image": "/assets/fight-staff-2.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 3,
            "title": "Сандалі піднесення",
            "text": "піднімуть тебе на чарівну гору Олімп",
            "image": "/assets/fight-staff-3.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 4,
            "title": "Чаша істини",
            "text": "вберігає від надмірного споживання",
            "image": "/assets/fight-staff-4.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 5,
            "title": "Дзеркало правди",
            "text": "проявляє істинні наміри супротивників",
            "image": "/assets/fight-staff-5.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 6,
            "title": "Меч-руйнівник міфів",
            "text": "руйнує міфи на своєму шляху",
            "image": "/assets/fight-staff-6.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 7,
            "title": "Щит неупередженості",
            "text": "захищає від забобонів і стереотипів",
            "image": "/assets/fight-staff-7.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 8,
            "title": "Вінок розсудливості",
            "text": "залишає розум холодним і неупередженим",
            "image": "/assets/fight-staff-8.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 9,
            "title": "Тризубець свідомості",
            "text": "пробуджує свідомість",
            "image": "/assets/fight-staff-9.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 10,
            "title": "Золоті нитки безсмертя",
            "text": "дарують тобі вічне життя",
            "image": "/assets/fight-staff-10.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        },
        {
            "id": 11,
            "title": "Блискавка віри",
            "text": "підказує, у що варто вірити й кому довіряти",
            "image": "/assets/fight-staff-11.png",
            "rune": "Not exists",
            "empty_rune": "Not exists"
        }
    ],
    enemies: [
        {
            "id": 1,
            "title": "Cyber Cerberus",
            "images": [
                "/assets/fight-enemy-1.png",
                "/assets/finish-enemy-1.png"
            ]
        },
        {
            "id": 2,
            "title": "F`n`X",
            "images": [
                "/assets/fight-enemy-2.png",
                "/assets/finish-enemy-2.png"
            ]
        },
        {
            "id": 3,
            "title": "Smart Harpia",
            "images": [
                "/assets/fight-enemy-3.png",
                "/assets/finish-enemy-3.png"
            ]
        },
        {
            "id": 4,
            "title": "Hyper Hydra",
            "images": [
                "/assets/fight-enemy-4.png",
                "/assets/finish-enemy-4.png"
            ]
        },
        {
            "id": 5,
            "title": "Manti-CORE",
            "images": [
                "/assets/fight-enemy-5.png",
                "/assets/finish-enemy-5.png"
            ]
        },
        {
            "id": 6,
            "title": "Mega Mars",
            "images": [
                "/assets/fight-enemy-6.png",
                "/assets/finish-enemy-6.png"
            ]
        },
        {
            "id": 7,
            "title": "miDuza",
            "images": [
                "/assets/fight-enemy-7.png",
                "/assets/finish-enemy-7.png"
            ]
        },
        {
            "id": 8,
            "title": "Technotaur",
            "images": [
                "/assets/fight-enemy-8.png",
                "/assets/finish-enemy-8.png"
            ]
        },
        {
            "id": 9,
            "title": "Neptune Pro",
            "images": [
                "/assets/fight-enemy-9.png",
                "/assets/finish-enemy-9.png"
            ]
        },
        {
            "id": 10,
            "title": "Neo Pluton",
            "images": [
                "/assets/fight-enemy-10.png",
                "/assets/finish-enemy-10.png"
            ]
        },
        {
            "id": 11,
            "title": "Electro Zeus",
            "images": [
                "/assets/fight-enemy-11-1.png",
                "/assets/fight-enemy-11-2.png",
                "/assets/fight-enemy-11-3.png",
                "/assets/fight-enemy-11-4.png"
            ]
        }
    ],
    fight: [
        {
            "enemy": 1,
            "questions": [
                {
                    "question_text": "<p>Міцна кава допоможе швидше протверезіти.</p>",
                    "answer": 0,
                    "correct_title": "ЄП!",
                    "incorrect_title": "УПС…",
                    "answer_text": "<p>Поєднання кофеїну й алкоголю створює короткочасне враження, наче повернувся заряд бадьорості й зосередженості. Але це хибне й оманливе відчуття. Такий «коктейль» особливо небезпечний водіям. </p>"
                },
                {
                    "question_text": "<p>Поєднання алкоголю й енергетиків підвищує ризики для здоров’я.</p>",
                    "answer": 1,
                    "correct_title": "САМЕ ТАК!",
                    "incorrect_title": "О НІ!",
                    "answer_text": "<p>Енергетичні напої не усувають ефектів від споживання алкоголю, але здатні їх маскувати. Так можна непомітно для себе випити зайвого й перевантажити свій організм, підвищуючи ризик появи різних захворювань.</p>"
                }
            ],
            "staff": 1
        },
        {
            "enemy": 2,
            "questions": [
                {
                    "question_text": "<p>Немає єдиної думки про те, скільки алкоголю можна випити без значної шкоди для здоров’я.</p>",
                    "answer": 1,
                    "correct_title": "ТАК І Є!",
                    "incorrect_title": "НЕСПОДІВАНО, АЛЕ…",
                    "answer_text": "<p>Єдиних міжнародних рекомендацій поки немає. Однак багато країн пропонують свої офіційні норми помірного споживання алкоголю. Їх дотримання знижує негативні наслідки для організму. </p>"
                },
                {
                    "question_text": "<p>Якщо в мене від природи висока толерантність до алкоголю, то можна випити більше.</p>",
                    "answer": 0,
                    "correct_title": "І ВСЕ ТИ ЗНАЄШ!",
                    "incorrect_title": "ЦЕ БУЛО САМОВПЕВНЕНО!",
                    "answer_text": "<p>Те, що ти не відчуваєш сп’яніння, не означає, що алкоголь не впливає на організм. Важливо контролювати кількість випитого, бо висока толерантність часто призводить до регулярного зловживання, що може спричиняти різні захворювання.</p>"
                }
            ],
            "staff": 2
        },
        {
            "enemy": 3,
            "questions": [
                {
                    "question_text": "<p>Міцний алкоголь відомого бренду в пакеті, а не у скляній пляшці, — це підробка.</p>",
                    "answer": 1,
                    "correct_title": "АВЖЕЖ!",
                    "incorrect_title": "УВАЖНІШЕ!",
                    "answer_text": "<p>Виробники відомих на весь світ міцних алкогольних напоїв ніколи не розливають свою продукцію в пакети — тільки у скляну тару. Купуючи підробку, ти ризикуєш своїм життям і здоров’ям. </p>"
                },
                {
                    "question_text": "<p>Фальсифікованим алкоголем можна отруїтися, але від нього не помреш.</p>",
                    "answer": 0,
                    "correct_title": "КРУТО, ЩО ЗНАЄШ!",
                    "incorrect_title": "НЕБЕЗПЕЧНО!",
                    "answer_text": "<p>Замість етилового спирту фальсифікований алкоголь може містити технічний спирт. Його вживання не тільки серйозно шкодить здоров’ю, а й може призвести до смерті.</p>"
                }
            ],
            "staff": 3
        },
        {
            "enemy": 4,
            "questions": [
                {
                    "question_text": "<p>Алкоголь сприяє хорошому сну.</p>",
                    "answer": 0,
                    "correct_title": "ТОЧНО!",
                    "incorrect_title": "НЕ ЗОВСІМ…",
                    "answer_text": "<p>Після випитого справді можна швидше заснути, але якість сну погіршується. Особливо страждає фаза швидкого сну — надзвичайно важлива для оперативної пам’яті й навчання.</p>"
                },
                {
                    "question_text": "<p>Сон допомагає швидше протверезіти.</p>",
                    "answer": 0,
                    "correct_title": "БІНГО!",
                    "incorrect_title": "НІТ!",
                    "answer_text": "<p>Сон не прискорює розщеплення й виведення алкоголю з організму. За годину тіло здорової людини без суттєвої шкоди для себе в середньому здатне «переробити» 10 г чистого спирту. Це 25 мл міцного алкоголю (40%), 100 мл столового вина (13%) або 250 мл пива (5%).</p>"
                }
            ],
            "staff": 4
        },
        {
            "enemy": 5,
            "questions": [
                {
                    "question_text": "<p>Навіть невеликий келих вина під час вагітності може зашкодити майбутній дитині.</p>",
                    "answer": 1,
                    "correct_title": "ТАК І Є!",
                    "incorrect_title": "ФАТАЛЬНА ПОМИЛКА!",
                    "answer_text": "<p>Споживання алкоголю під час вагітності загрожує здоров’ю майбутньої дитини. Алкоголь легко переміщується кровотоком від матері до дитини та може вплинути на розвиток її мозку й інших органів.</p>"
                },
                {
                    "question_text": "<p>Випиваючи порівну, чоловік і жінка сп’яніють однаково.</p>",
                    "answer": 0,
                    "correct_title": "ПЕВНА РІЧ!",
                    "incorrect_title": "ХИБНА ДУМКА!",
                    "answer_text": "<p>Жінка сп’яніє швидше. Зазвичай вона має меншу вагу тіла, кількість води в організмі та ферментів, що розщеплюють етанол. Тому навіть якщо обоє вип’ють однаково, концентрація алкоголю в крові жінки буде вищою, ніж у чоловіка. </p>"
                }
            ],
            "staff": 5
        },
        {
            "enemy": 6,
            "questions": [
                {
                    "question_text": "<p>Якщо змішувати різні напої за вечір, то швидше п’янієш.</p>",
                    "answer": 0,
                    "correct_title": "ЗНАЄШ ФІШКУ!",
                    "incorrect_title": "ЦЕ ВСЕ КАЗОЧКИ!",
                    "answer_text": "<p>Перейдеш із горілки на пиво чи навпаки, — не важливо. Впливає тільки кількість випитого.</p>"
                },
                {
                    "question_text": "<p>Якщо випити вино, пиво й горілку на одній тусовці, то сильне похмілля на ранок гарантоване.</p>",
                    "answer": 0,
                    "correct_title": "ФАКТ!",
                    "incorrect_title": "БУДЕШ ЗНАТИ!",
                    "answer_text": "<p>Сильне похмілля виникає передусім через зневоднення організму й надмірне споживання алкоголю, а не через змішування різних напоїв. Стеж за кількістю випитого та пий склянку води після кожного келиха алкоголю: це допоможе вивести його з організму.</p>"
                }
            ],
            "staff": 6
        },
        {
            "enemy": 7,
            "questions": [
                {
                    "question_text": "<p>У людини може розвинутися стійкість до алкоголю.</p>",
                    "answer": 1,
                    "correct_title": "ЩЕ Й ЯК!",
                    "incorrect_title": "ФЕЙЛ!",
                    "answer_text": "<p>Якщо довго й регулярно зловживати алкогольними напоями, чутливість організму до алкоголю може знизитися. Помічаєш, що для сп’яніння треба випити більше, ніж зазвичай? Це тривожний дзвіночок!</p>"
                },
                {
                    "question_text": "<p>Холодний душ допоможе швидше протверезіти.</p>",
                    "answer": 0,
                    "correct_title": "ОТОЖ!",
                    "incorrect_title": "НІ, НІ І ЩЕ РАЗ НІ!",
                    "answer_text": "<p>Холодний душ ніяк не прискорить процес виведення алкоголю з організму, натомість може призвести до спазму судин або непритомності сп’янілої людини. Протверезіти допоможе тільки час.</p>"
                }
            ],
            "staff": 7
        },
        {
            "enemy": 8,
            "questions": [
                {
                    "question_text": "<p>Поєднувати алкоголь із ліками небезпечно для здоров’я.</p>",
                    "answer": 1,
                    "correct_title": "СПРАВДІ!",
                    "incorrect_title": "ГРАЄШСЯ З ВОГНЕМ!",
                    "answer_text": "<p>Змішування алкоголю й лікарських речовин може мати небезпечні й непередбачувані наслідки. Навіть якщо в інструкції про це нічого не сказано, краще проконсультуватися з лікарем.</p>"
                },
                {
                    "question_text": "<p>Алкоголь допомагає зігрітися. </p>",
                    "answer": 0,
                    "correct_title": "У САМІСІНЬКУ ЦІЛЬ!",
                    "incorrect_title": "ПРОМАХ!",
                    "answer_text": "<p>Споживання алкоголю створює хибне відчуття, наче стає тепліше. Насправді нагрівається тільки шкіра, яка швидко холоне. А внутрішні органи в цей час втрачають тепло і загальна температура тіла знижується.</p>"
                }
            ],
            "staff": 8
        },
        {
            "enemy": 9,
            "questions": [
                {
                    "question_text": "<p>Алкоголь підвищує сексуальну активність.</p>",
                    "answer": 0,
                    "correct_title": "ДІЙСНО!",
                    "incorrect_title": "ЙОЙ!",
                    "answer_text": "<p>Алкоголь не підвищує сексуальну активність, а навпаки — може призвести до сексуальної дисфункції.</p>"
                },
                {
                    "question_text": "<p>Алкоголь рятує від депресії.</p>",
                    "answer": 0,
                    "correct_title": "БЕЗПЕРЕЧНО!",
                    "incorrect_title": "ЦЕ НЕ ТАК!",
                    "answer_text": "<p>Зловживання алкоголем може тільки посилити прояви депресії. Спиртне тимчасово розслабляє й гальмує нервову систему, але згодом рівень тривожності підвищується.</p>"
                }
            ],
            "staff": 9
        },
        {
            "enemy": 10,
            "questions": [
                {
                    "question_text": "<p>Якщо добре закушувати — не сп’янієш.</p>",
                    "answer": 0,
                    "correct_title": "ПРАВИЛЬНО!",
                    "incorrect_title": "ПОМИЛОЧКА!",
                    "answer_text": "<p>Їжа тільки сповільнює процес всмоктування алкоголю в кров, але не вберігає від сп’яніння. </p>"
                },
                {
                    "question_text": "<p>Активоване вугілля до або після споживання алкоголю вбереже від сп’яніння.</p>",
                    "answer": 0,
                    "correct_title": "ТИ В ТЕМІ!",
                    "incorrect_title": "ОЦЕ ДАЄШ…",
                    "answer_text": "<p>Немає жодних наукових доказів, що активоване вугілля може абсорбувати алкоголь, перешкоджаючи його потраплянню в кров.</p>"
                }
            ],
            "staff": 10
        }
    ],
    boss_fight: [
        {
            "enemy": 11,
            "questions": [
                {
                    "question_text": "<p>Регулярне і тривале зловживання алкоголем збільшує ймовірність виникнення різних захворювань.</p>",
                    "answer": 1,
                    "correct_title": "ТВОЯ ПРАВДА!",
                    "incorrect_title": "ТА Й ТАКЕ…",
                    "answer_text": "<p>Регулярне надмірне споживання алкоголю протягом тривалого часу може призвести до підвищеного тиску, інсульту, деяких видів раку й цирозу печінки.</p>"
                },
                {
                    "question_text": "<p>Алкоголь допомагає під час застуди та грипу.</p>",
                    "answer": 0,
                    "correct_title": "ТАК!",
                    "incorrect_title": "ПФФ… НІ!",
                    "answer_text": "<p>Алкоголь — не ліки від застуди чи грипу. Надмірна його кількість не зміцнює, а послаблює організм.</p>"
                },
                {
                    "question_text": "<p>Невелика кількість алкоголю за годину до поїздки — не завада керуванню автомобілем.</p>",
                    "answer": 0,
                    "correct_title": "ЖОДНИХ СУМНІВІВ!",
                    "incorrect_title": "О НІ, ТІЛЬКИ НЕ ЦЕ!",
                    "answer_text": "<p>Час розщеплення й виведення алкоголю з організму — дуже індивідуальний. Це залежить від міцності й кількості спожитого, віку людини, її статі, ваги, генетики, а також загального стану організму. Тож універсальне правило для всіх — алкоголь і керування автомобілем — несумісні, навіть якщо випито мало.</p>"
                },
                {
                    "question_text": "<p>25 мл віскі (40%) навантажує організм більше, ніж 250 мл пива (5%).</p>",
                    "answer": 0,
                    "correct_title": "МАЄШ РАЦІЮ!",
                    "incorrect_title": "А МАМА КАЗАЛА!",
                    "answer_text": "<p>Немає більш «важких» і більш «легких» алкогольних напоїв. Єдина різниця — обсяг етанолу (чистого спирту), що міститься в них. У 25 мл віскі (40%) і в 250 мл пива (5%) однакова кількість чистого спирту — 10 г.</p>"
                }
            ],
            "staff": 11
        }
    ],
    trainings: [
        '<p>Алкоголь дуже швидко починає впливати на організм. Усе тому, що він починає всмоктуватися ще у слизовій оболонці рота. А далі кров розносить усе тілом.</p><p>Молекули етилового спирту дуже малі і вони легко розчиняються у воді й жирах, що є основними компонентами людського організму. До речі, близько 80% спожитого алкоголю всмоктує тонка кишка, а решту «забирає» слизова шлунка.</p>',
        '<p>Піт, сеча і дихання — ось три шляхи, якими 10% алкоголю виходять із організму. Це і пояснює ефективність алкотестерів, що визначають рівень алкоголю в крові.</p><p>А 90% усього алкоголю «фінішує» у печінці. Саме вона його поетапно нейтралізує, перетворюючи на воду й вуглекислий газ, що потім виводяться з організму.</p>',
        '<p>5 хвилин після вживання — ось скільки часу потрібно алкоголю, щоб вплинути на мозок. Саме там його ефект проявляється найшвидше. А все тому, що ще до потрапляння в печінку алкоголь діє на інші внутрішні органи, що містять багато води й кров’яних судин.</p>',
        '<p>Що більшу кількість алкоголю ти вживаєш за раз, то більше навантажуєш організм. Печінка не переходить у «екстремальний режим роботи», а працює, як і зазвичай. Їй потрібно близько години, щоб нейтралізувати 10 г чистого спирту, що міститься, наприклад, у 25 мл горілки чи віскі (40%), 100 мл столового вина (13%) або 250 мл пива (5%).</p>',
        '<p>Гени, вік, стать і загальний стан здоров’я — все це визначає те, як тіло реагує на випитий алкоголь. Залежно від кількості, якості, частоти та тривалості вживання алкоголю різниться і його вплив на організм.</p>',
        '<p>Пити алкоголь чи ні — вибір кожного. Проте ніхто не має вживати його заради користі для здоров’я.</p><p>Важливо завжди пам’ятати про помірне споживання, уникати водіння напідпитку і не вживати алкоголь під час вагітності.</p>',
    ]
};

new Vue({
    render: h => h(App),
}).$mount('#app')
