<template>
  <section class="game--stage">
    <transition name="preloader-transition">
      <preloader v-if="preload" :assets="assets"></preloader>
    </transition>
    <div v-if="!preload" class="wrapper" style="padding: 2rem;">
      <div class="bg">
        <img src="/assets/home-bg.jpg">
      </div>
      <div style="display: table; width: 100%; height: 100%; position: relative; z-index: 1">
        <div style="display: table-row; vertical-align: middle">
          <div style="display: table-cell">
            <div class="frame--top"></div>
          </div>
        </div>
        <div style="display: table-row; height: 100%;">
          <div style="display: table-cell; vertical-align: middle">
            <div class="logo">
              <img src="/assets/logo.png">
            </div>
            <button class="btn btn-1" @click="startIntro()">
              <span>Грати</span>
            </button></div>
        </div>

        <div style="display: table-row;">
          <div style="display: table-cell;">
            <div class="frame--bottom"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Preloader from "@/components/Preloader";
export default{
  name: 'home-stage',
  components: {Preloader},
  props: ['appData'],
  data(){
    return {
      preload: true,
      assets: [
          '/assets/app-bg.jpg',
          '/assets/frame-bottom.png',
          '/assets/frame-top.png',
          '/assets/home-bg.jpg',
          '/assets/intro-1.jpg',
          '/assets/intro-2.jpg',
          '/assets/intro-3.jpg',
          '/assets/intro-4.jpg',
          '/assets/intro-5.jpg',
          '/assets/logo.png',
          '/assets/main-bg.jpg',
          '/assets/myst-1.jpg',
          '/assets/myst-2.jpg',
      ]
    };
  },
  methods: {
    init: function(){


    },
    startIntro: function(){

      this.appData.stage = 'intro-stage';
    }
  }
}

</script>