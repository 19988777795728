<template>
  <section class="game--stage">
    <div class="wrapper">
      <div id="intro--swiper">
        <div class="swiper-container" style="height: 100%">
          <div class="swiper-wrapper" style="height: 100%">
            <div class="swiper-slide intro--slide">
              <div class="bg"><img src="/assets/intro-1.jpg"></div>
              <div style="width: 100%; height: 100%; position: relative">
                <div class="intro--text">
                  <p>
                    І поглинув інтернет весь світ. І наповнив його фейками про споживання алкоголю.
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide intro--slide">
              <div class="bg"><img src="/assets/intro-2.jpg"></div>
              <div style="width: 100%; height: 100%; position: relative">
                <div class="intro--text">
                  <p>
                    Тільки одне місце ще залишилося, де можна правду знайти. Олімп. Містична гора.
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide intro--slide">
              <div class="bg"><img src="/assets/intro-3.jpg"></div>
              <div style="width: 100%; height: 100%; position: relative">
                <div class="intro--text">
                  <p>Живуть там боги, що істину від смертних приховують.</p>
                  <p>Кинь їм виклик!</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide intro--slide">
              <div class="bg"><img src="/assets/intro-4.jpg"></div>
              <div style="width: 100%; height: 100%; position: relative">
                <div class="intro--text">
                  <p>І в битву проти алкоміфів вступи. Тільки в бою за правду ти розірвеш кайдани ілюзій і станеш рівнею богам.</p>
                </div>
              </div>
            </div>

            <div class="swiper-slide intro--slide">
              <div class="bg"><img src="/assets/intro-5.jpg"></div>
              <div style="width: 100%; height: 100%; position: relative">
                <div style="position: absolute; width: 100%; bottom: 19.8%; left: 0; text-align: center;">
                  <button class="btn btn-1" @click="startHeroSelect()"><span>Обрати героя</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper--navigation--button swiper--next"></div>
        <div class="swiper--navigation--button swiper--prev disabled"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';
import SwiperCore, {
  EffectFade,
} from 'swiper/core';
SwiperCore.use([EffectFade]);

export default{
  name: 'intro-stage',
  props: ['appData'],
  data(){
    return {};
  },
  mounted(){

    this.init();
  },
  methods: {
    init(){

      let node = this.$el;
      let swiperNode = node.querySelector('#intro--swiper');
      let swiperContainer = swiperNode.querySelector('.swiper-container');
      let nextButton = swiperNode.querySelector('.swiper--next');
      let prevButton = swiperNode.querySelector('.swiper--prev');

      swiperNode.swiperInstance = new Swiper(swiperContainer, {
        spaceBetween: 0,
        effect: "fade",
        on: {
          init: function(e){
            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }
          },
          slideChange: function(e){

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }

            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }
          }
        }
      });

      nextButton.addEventListener('click', function(){
        swiperNode.swiperInstance.slideNext();
      });

      prevButton.addEventListener('click', function(){
        swiperNode.swiperInstance.slidePrev();
      });
    },
    startHeroSelect(){

      this.appData.stage = 'hero-select-stage';
    }
  }
}
</script>