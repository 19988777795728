<template>
  <section id="boss" class="game--stage" :data-state="state" :data-step="step">
    <div class="bg olimp-bg">
      <img src="/assets/mountain.jpg" :style="{top: olimpTranslate +'%'}">
    </div>
    <div v-if="state==='story'" class="wrapper" style="padding: 30px;">
      <div class="bg zeus-bg">
        <img src="/assets/enemy-story-11.jpg">
      </div>
      <div style="height: 100%; width: 100%; position: relative;">
        <div style="position: absolute; width: 100%; bottom: 19.8%; left: 0; text-align: center;">
          <button class="btn btn-1" @click="startIntro()"><span>ВИРІШАЛЬНА БИТВА</span></button>
        </div>
      </div>
    </div>
    <div v-if="state!=='story'" class="wrapper">
      <div style="height: 100%; width: 100%; position: relative">
        <div class="fight--enemy">
          <img v-if="enemy" :src="enemy.images[step]">
        </div>
        <div class="fight--enemy--title">
          <span v-if="enemy" v-html="enemy.title"></span>
        </div>
        <div class="fight--vs">
          <img src="/assets/fight-fight.png">
        </div>
        <div class="fight--flash fight--flash-1">
          <img src="/assets/flash-1.png">
          <img src="/assets/flash-2.png">
          <img src="/assets/flash-3.png">
          <img src="/assets/flash-4.png">
        </div>
        <div class="fight--flash fight--flash-2">
          <img src="/assets/flash-1.png">
          <img src="/assets/flash-2.png">
          <img src="/assets/flash-3.png">
          <img src="/assets/flash-4.png">
        </div>
        <div class="fight--hero"><img :src="hero.fight_image"></div>
        <div class="fight--hero--title"><span>{{ hero.title }}</span></div>
        <div v-if="state === 'question'" class="fight--question">
          <div style="display: table; width: 100%; height: 100%">
            <div style="display: table-row">
              <div style="display: table-cell">
                <div class="fight--question--step">{{ (step + 1) + ' / ' + levelData.questions.length }}</div>
              </div>
            </div>
            <div style="display: table-row; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                <div class="fight--question--text" v-html="levelData.questions[step].question_text"></div>
              </div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell">
                <div style="display: flex; justify-content: space-between">
                  <button class="btn btn-4 btn-small" @click="showAnswer(1)"><span>Правда</span></button>
                  <button class="btn btn-5 btn-small" @click="showAnswer(0)"><span>Міф</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="state==='correct' || state==='incorrect'" class="fight--answer">
          <div style="display: table; width: 100%; height: 100%">
            <div style="display: table-row; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                <div v-if="state==='correct'" class="fight--answer--title" v-html="levelData.questions[step].correct_title"></div>
                <div v-if="state==='incorrect'" class="fight--answer--title" v-html="levelData.questions[step].incorrect_title"></div>
                <div class="fight--answer--text" v-html="levelData.questions[step].answer_text"></div>
              </div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell">
                <div style="display: flex; justify-content: center">
                  <button id="correct-button" class="btn btn-3 btn-small" @click="showResult()"><span>Ок</span></button>
                  <button id="incorrect-button" class="btn btn-2 btn-small" @click="showResult()"><span>Ок</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="state==='result'" class="fight--result" style="z-index: 4; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0">
      <div class="fight--result" style="z-index: 4; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0">
        <div class="bg">
          <img src="/assets/fight-bg.jpg">
        </div>
        <div style="display: table; width: 100%; height: 100%; padding: 1.8rem; position: relative">
          <div style="display: table-row; vertical-align: middle">
            <div style="display: table-cell">
              <div class="frame--top"></div>
            </div>
          </div>
          <div style="display: table-row; height: 100%;">
            <div style="display: table-cell; vertical-align: middle">
              <div class="fight--result--staff"><img :src="staff.image"></div>
              <div class="fight--result--title" v-html="staff.title"></div>
              <div class="fight--result--text" v-html="staff.description"></div>
              <div class="fight--result--button">
                <button class="btn btn-1" @click="startOutro()"><span>Взяти</span></button>
              </div>
            </div>
          </div>
          <div style="display: table-row;">
            <div style="display: table-cell;">
              <div class="frame--bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default{
  name: 'boss-fight-stage',
  props: ['appData'],
  data(){
    return {
      step: 0,
      state: 'story',
      staff: this.getById(this.appData.staffs, 11),
      hero: this.appData.gameState.hero,
      enemy: this.getById(this.appData.enemies, 11),
      levelData: this.appData.boss_fight[0],
      olimpTranslate: -100
    };
  },
  mounted(){

  },
  methods: {
    initLevel: function(){

      let self = this;

      self.state = 'idle';

      self.step = 0;
      self.levelResult = 0;

      self.initStep();

      setTimeout(function(){
        self.state = 'intro';
      }, 200)

    },
    initStep: function(){

      let self = this;

      if(self.step === 0){
        setTimeout(function(){
          self.state = 'question';
        }, 3000);
      }else{
        self.state = 'question';
      }

    },
    startIntro(){

      this.initLevel();

    },
    startOutro(){

      this.appData.stage = 'outro-stage'
    },
    showAnswer(answer){

      let self = this;

      if(answer === self.levelData.questions[self.step].answer){
        self.levelResult++;
        self.olimpTranslate = -(100 - self.levelResult * 25);
        self.state = 'correct';
      }else{
        self.state = 'incorrect';
      }
    },
    showResult(){
      let self = this;

      if(this.step === self.levelData.questions.length-1){

        if(self.levelResult === self.levelData.questions.length){
          self.appData.gameState.progress.push(11);
          setTimeout(function(){
            self.state = 'result';
          }, 64);
        }else{
          this.startOutro();
        }

      }else{

        self.step++;
        self.initStep();
      }
    }
  }
}
</script>

