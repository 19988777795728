<template>
  <section id="fight" class="game--stage" :data-state="state">
    <transition name="preloader-transition">
      <preloader v-if="preload" :assets="assets"></preloader>
    </transition>
    <div v-if="!preload" class="wrapper">
      <div class="bg">
        <img src="/assets/fight-bg.jpg">
      </div>
      <div style="height: 100%; width: 100%; position: relative">

<!--        <div style="width: 100%; max-width: 600px; position: absolute;top: 0;right: 0">-->
          <div class="fight--enemy"><img :src="enemy && enemy.images[step]"></div>
<!--        </div>-->

        <div class="fight--enemy--title"><span v-html="enemy && enemy.title"></span></div>
        <div class="fight--vs"><img src="/assets/fight-vs.png"></div>
        <div class="fight--flash fight--flash-1" style="margin-top: 20px">
          <img src="/assets/flash-1.png">
          <img src="/assets/flash-2.png">
          <img src="/assets/flash-3.png">
          <img src="/assets/flash-4.png">
        </div>
        <div class="fight--flash fight--flash-2">
          <img src="/assets/flash-1.png">
          <img src="/assets/flash-2.png">
          <img src="/assets/flash-3.png">
          <img src="/assets/flash-4.png">
        </div>

<!--        <div style="width: 100%; max-width: 600px; position: absolute;bottom: 0;left: 0">-->
          <div class="fight--hero"><img :src="hero && hero.fight_image"></div>
<!--        </div>-->

        <div class="fight--hero--title"><span>{{ hero && hero.title}}</span></div>
        <div v-if="state === 'question'" class="fight--question">
          <div style="display: table; width: 100%; height: 100%">
            <div style="display: table-row">
              <div style="display: table-cell">
                <div class="fight--question--step">{{ (step + 1) + ' / ' + levelData.questions.length }}</div>
              </div>
            </div>
            <div style="display: table-row; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                <div class="fight--question--text" v-html="levelData.questions[step].question_text"></div>
              </div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell">
                <div style="display: flex; justify-content: space-between">
                  <button class="btn btn-4 btn-small" @click="showAnswer(1)"><span>Правда</span></button>
                  <button class="btn btn-5 btn-small" @click="showAnswer(0)"><span>Міф</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="state==='correct' || state==='incorrect'" class="fight--answer">
          <div style="display: table; width: 100%; height: 100%">
            <div style="display: table-row; height: 100%">
              <div style="display: table-cell; vertical-align: middle">
                  <div v-if="state==='correct'" class="fight--answer--title" v-html="levelData.questions[step].correct_title"></div>
                  <div v-if="state==='incorrect'" class="fight--answer--title" v-html="levelData.questions[step].incorrect_title"></div>
                  <div class="fight--answer--text" v-html="levelData.questions[step].answer_text"></div>
              </div>
            </div>
            <div style="display: table-row;">
              <div style="display: table-cell">
                <div style="display: flex; justify-content: center">
                  <button id="correct-button" class="btn btn-3 btn-small" @click="showResult()"><span>Ок</span></button>
                  <button id="incorrect-button" class="btn btn-2 btn-small" @click="showResult()"><span>Ок</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <transition name="popup-transition">
          <div v-if="state==='result'" class="fight--result" style="z-index: 4; display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0">
            <div class="bg">
              <img src="/assets/fight-bg.jpg">
            </div>
            <div style="display: table; width: 100%; height: 100%; padding: 1.8rem; position: relative">
              <div style="display: table-row; vertical-align: middle">
                <div style="display: table-cell">
                  <div class="frame--top"></div>
                </div>
              </div>
              <div style="display: table-row; height: 100%;">
                <div style="display: table-cell; vertical-align: middle">
                  <div class="fight--result--staff"><img :src="staff.image"></div>
                  <div class="fight--result--title" v-html="staff.title"></div>
                  <div class="fight--result--text" v-html="staff.text"></div>
                  <div class="fight--result--button">
                    <button class="btn btn-1" @click="nextLevel()"><span>Взяти</span></button>
                  </div>
                </div>
              </div>
              <div style="display: table-row;">
                <div style="display: table-cell;">
                  <div class="frame--bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import Preloader from "@/components/Preloader";
export default{
  name: 'regular-fight-stage',
  components: {Preloader},
  props: ['appData'],
  data(){

    return {
      preload: true,
      assets: [
        '/assets/fight-bg.jpg',
        '/assets/fight-fight.png',
        '/assets/fight-vs.png',
        '/assets/flash-1.png',
        '/assets/flash-2.png',
        '/assets/flash-3.png',
        '/assets/flash-4.png',
        '/assets/fight-enemy-1.png',
        '/assets/fight-enemy-2.png',
        '/assets/fight-enemy-3.png',
        '/assets/fight-enemy-4.png',
        '/assets/fight-enemy-5.png',
        '/assets/fight-enemy-6.png',
        '/assets/fight-enemy-7.png',
        '/assets/fight-enemy-8.png',
        '/assets/fight-enemy-9.png',
        '/assets/fight-enemy-10.png',
        '/assets/fight-enemy-11-1.png',
        '/assets/fight-enemy-11-2.png',
        '/assets/fight-enemy-11-3.png',
        '/assets/fight-enemy-11-4.png',
        '/assets/finish-enemy-1.png',
        '/assets/finish-enemy-2.png',
        '/assets/finish-enemy-3.png',
        '/assets/finish-enemy-4.png',
        '/assets/finish-enemy-5.png',
        '/assets/finish-enemy-6.png',
        '/assets/finish-enemy-7.png',
        '/assets/finish-enemy-8.png',
        '/assets/finish-enemy-9.png',
        '/assets/finish-enemy-10.png',
        '/assets/fight-staff-1.png',
        '/assets/fight-staff-2.png',
        '/assets/fight-staff-3.png',
        '/assets/fight-staff-4.png',
        '/assets/fight-staff-5.png',
        '/assets/fight-staff-6.png',
        '/assets/fight-staff-7.png',
        '/assets/fight-staff-8.png',
        '/assets/fight-staff-9.png',
        '/assets/fight-staff-10.png',
        '/assets/fight-staff-11.png'
      ],
      state: 'idle',
      level: 0,
      step: 0,
      levelData: null,
      hero: null,
      enemy: null,
      staff: null,
    };
  },
  watch: {
    preload: function(value){
      if(value === false){
        this.$nextTick(function(){
          this.init();
        });
      }
    }
  },
  methods: {
    init(){
      this.initLevel();
    },
    initLevel(){

      let self = this;

      self.step = 0;
      self.levelData = self.appData.fight[self.level];
      self.levelResult = 0;
      self.hero = self.appData.gameState.hero;
      self.enemy = self.getById(self.appData.enemies, self.levelData.enemy);
      self.staff = self.getById(self.appData.staffs, self.levelData.staff);

      self.state = 'idle';

      setTimeout(function(){
        self.state = 'intro';
        self.initStep();
      }, 200)
    },
    initStep(){

      let self = this;

      if(self.step === 0){

        setTimeout(function(){
          self.state = 'question';
        }, 2400);
      }else{

        self.state = 'question';
      }
    },
    showAnswer(answer){

      let self = this;

      if(answer == self.levelData.questions[self.step].answer){
        self.levelResult++;
        self.state = 'correct';
      }else{
        self.state = 'incorrect';
      }
    },
    showResult(){

      let self = this;

      if(self.step === self.levelData.questions.length-1){

        if(self.levelResult === self.levelData.questions.length){

          self.appData.gameState.progress.push(self.staff.id);
          self.state = 'result';
        }else{

          self.nextLevel();
        }
      }else{

        self.step++;
        // self.state = 'idle';

        self.initStep();
      }

    },

    nextLevel(){

      let self = this;

      self.level++;

      if(self.level < self.appData.fight.length){

        self.initLevel();
      }else{

        self.startBossFight();
      }

    },

    startBossFight(){

      this.appData.stage = 'boss-fight-stage';
    }
  }
}
</script>