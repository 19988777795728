<template>
  <div id="app">
    <transition name="stage-transition">
      <component :is="appData.stage" :key="appData.stage" :appData="appData"></component>
    </transition>
  </div>
</template>

<script>

import HomeStage from "@/components/HomeStage";
import IntroStage from "@/components/IntroStage";
import HeroSelectStage from "@/components/HeroSelectStage";
import TrainingStage from "@/components/TrainingStage";
import RegularFightStage from "@/components/RegularFightStage";
import BossFightStage from "@/components/BossFightStage";
import OutroStage from "@/components/OutroStage";

export default {
  name: 'App',
  components: {
    IntroStage,
    HomeStage,
    HeroSelectStage,
    TrainingStage,
    RegularFightStage,
    BossFightStage,
    OutroStage
  },
  data(){

    return {
      appData: window.appData
    }
  },
  mounted(){

    this.init();
  },
  methods: {

    init(){

      let self = this;
      window.addEventListener('resize', function(){
        self.appData.device = {
          width: window.innerWidth,
          height: window.innerHeight,
          orientation: window.innerWidth / window.innerHeight >= 1 ? 'landscape' : 'portrait'
        }
      });

      // self.appData.gameState.hero = null;
      // self.appData.gameState.progress = [];

    },
    startNewGame(){
      let self = this;
      self.appData.stage = 'intro-stage';
      self.appData.gameState.hero = null;
      self.appData.gameState.progress = [];
    }
  }
}
</script>
