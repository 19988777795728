<template>
  <section id="outro" class="game--stage" :data-state="state">

    <transition name="preloader-transition">
      <preloader v-if="preload" :assets="assets" :callback="null"></preloader>
    </transition>

    <transition name="popup-transition">
      <div v-if="popup === 'about'" class="wrapper" style="z-index: 3; padding: 3rem;">
        <div style="display: block; position: relative; top: 0; left: 0; width: 100%;">
          <div class="training--description" style="opacity: 1;">
            <div class="popup-close-btn" @click="closePopup()" style="position: absolute; z-index: 2; top: 15px; right: 15px; width: 22px; height: 22px">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.90046L16.6158 19.4067L18.7923 24.9967L30.2588 36.3876L36.1513 33.0125L2.91971 0L0 2.90046Z" fill="#F808FF"/>
                <path d="M61.2076 24.9967L63.3841 19.4067L79.9999 2.90046L77.0802 0L43.8486 33.0125L49.7411 36.3876L61.2076 24.9967Z" fill="#F808FF"/>
                <path d="M61.2076 55.0032L49.7411 43.6123L43.8486 46.9874L77.0802 79.9999L79.9999 77.0994L63.3841 60.5932L61.2076 55.0032Z" fill="#F808FF"/>
                <path d="M18.7923 55.0032L16.6158 60.5932L0 77.0994L2.91971 79.9999L36.1513 46.9874L30.2588 43.6123L18.7923 55.0032Z" fill="#F808FF"/>
              </svg>
            </div>
            <div style="display: table; height: 100%; width: 100%">
              <div style="display: table-row; height: 100%">
                <div style="display: table-cell; vertical-align: middle; padding: 3rem 0;">
                  <div style="position: relative; width: 100%; height: 100%">
                    <div class="training--text" style="overflow: hidden; max-width: 100%">
                      <div style="display: block; width: 200%; overflow: auto; height: 100%">
                        <div style="display: block; width: 50%">
                          <div class="training--title">Про гру</div>
                          <p>В інтернеті — десятки міфів про алкоголь. Буває складно зрозуміти, де правда, але після цієї файтинг-гри ми спокійні: ти впораєшся і не поведешся на провокації. Саме для цього ми і створили веб-застосунок. Без зайвого моралізаторства і з науково обґрунтованою інформацією. Візьми ці факти на озброєння і не дай алкоміфам задурманити твій розум.</p>
                          <p>Цей проєкт існує в межах стратегії з відповідального споживання алкоголю, яку реалізує компанія PERNOD RICARD UKRAINE. Розуміючи, що наші напої об’єднують людей, але водночас і можуть зашкодити, якщо ними зловживати, ми беремося розвивати культуру відповідального споживання алкоголю.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div style="display: table-row">
                <div style="display: table-cell">
                  <div class="frame--bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="wrapper">
      <div class="bg">
        <img src="/assets/outro-bg.jpg">
      </div>
      <div style="display: block; position: relative; top: 0; left: 0; width: 100%; height: 100%;">
        <div class="outro--arc">
          <img src="/assets/outro-arc.png" style="width: 100%; height: auto; position: relative">
          <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
            <div class="outro--arc--icon" data-rel-staff="5">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-5.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="10">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-10.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="4">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-4.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="6">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-6.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="8">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-8.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="1">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-1.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="9">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-9.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="2">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-2.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="7">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-7.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="3">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-3.png">
              </div>
            </div>
            <div class="outro--arc--icon" data-rel-staff="11">
              <div class="outro--arc--icon--image">
                <img src="/assets/arc-icon-11.png">
              </div>
            </div>

          </div>
        </div>
        <div class="outro--doors" style="z-index: 2">
          <div class="outro--door--left">
            <img src="/assets/outro-door-left.png">
          </div>
          <div class="outro--door--right">
            <img src="/assets/outro-door-right.png">
          </div>
          <div class="outro--light">
            <img src="/assets/outro-light.png">
          </div>
        </div>

        <div class="outro--hero" style="width: 100%; height: 100%; position:absolute; z-index: 1">
          <img :src="appData.gameState.hero.victory_image" style="object-position: center bottom">
        </div>

        <div class="outro--footer" style="z-index: 2">
          <div class="outro--stone">
            <img src="/assets/outro-stone.png">
            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
              <div class="outro--stone--icon" data-rel-staff="1">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-1.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="2">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-2.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="3">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-3.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="4">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-4.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="5">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-5.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="6">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-6.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="7">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-7.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="8">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-8.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="9">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-9.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="10">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-10.png">
                </div>
              </div>
              <div class="outro--stone--icon" data-rel-staff="11">
                <div class="outro--stone--icon--image">
                  <img src="/assets/outro-stone-icon-11.png">
                </div>
              </div>
            </div>

          </div>
          <div style="margin-top: 30px;" class="">
            <button class="btn btn-1" @click="openDoor()"><span>Відчинити двері</span></button>
          </div>
        </div>

        <div class="outro--result">
          <div class="outro--result--text" style="margin-bottom: 30px;">
            <div class="intro--text" style="position: relative; opacity: 1; transform: none;margin: auto;left: 0;" v-html="result_text"></div>
          </div>
          <div style="display: block; width: 100%; max-width: 400px; margin: auto">
            <div class="outro--result--share">
              <div style="display: flex; justify-content: space-between; margin-top: 1.5rem;">
                <button class="btn btn-fb" @click="share('fb')"></button>
                <button class="btn btn-viber" @click="share('viber')"></button>
                <button class="btn btn-telegram" @click="share('telegram')"></button>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 1.5rem;">
              <button class="btn btn-1 btn-small btn-small-font" @click="showAbout()"><span>Про гру</span></button>
              <button class="btn btn-1 btn-small btn-small-font" @click="resetGame()"><span>Пройти ще раз</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Preloader from "@/components/Preloader";
export default{
  name: 'outro-stage',
  components: { Preloader },
  props: ['appData'],
  data(){
    return {
      preload: true,
      assets: [
        '/assets/arc-empty-1.png',
        '/assets/arc-empty-2.png',
        '/assets/arc-empty-3.png',
        '/assets/arc-empty-4.png',
        '/assets/arc-empty-5.png',
        '/assets/arc-empty-6.png',
        '/assets/arc-empty-7.png',
        '/assets/arc-empty-8.png',
        '/assets/arc-empty-9.png',
        '/assets/arc-empty-10.png',
        '/assets/arc-empty-11.png',
        '/assets/arc-icon-1.png',
        '/assets/arc-icon-2.png',
        '/assets/arc-icon-3.png',
        '/assets/arc-icon-4.png',
        '/assets/arc-icon-5.png',
        '/assets/arc-icon-6.png',
        '/assets/arc-icon-7.png',
        '/assets/arc-icon-8.png',
        '/assets/arc-icon-9.png',
        '/assets/arc-icon-10.png',
        '/assets/arc-icon-11.png',
        '/assets/outro-arc.png',
        '/assets/outro-arc-2.png',
        '/assets/outro-arc-3.png',
        '/assets/outro-arc-4.png',
        '/assets/outro-arc-5.png',
        '/assets/outro-arc-6.png',
        '/assets/outro-arc-7.png',
        '/assets/outro-arc-10.png',
        '/assets/outro-arc-icon-1.png',
        '/assets/outro-arc-icon-7.png',
        '/assets/outro-arc-icon-8.png',
        '/assets/outro-arc-icon-9.png',
        '/assets/outro-bg.jpg',
        '/assets/outro-door-left.png',
        '/assets/outro-door-right.png',
        '/assets/outro-light.png',
        '/assets/outro-stone.png',
        '/assets/outro-stone-icon-1.png',
        '/assets/outro-stone-icon-2.png',
        '/assets/outro-stone-icon-3.png',
        '/assets/outro-stone-icon-4.png',
        '/assets/outro-stone-icon-5.png',
        '/assets/outro-stone-icon-6.png',
        '/assets/outro-stone-icon-7.png',
        '/assets/outro-stone-icon-8.png',
        '/assets/outro-stone-icon-9.png',
        '/assets/outro-stone-icon-10.png',
        '/assets/outro-stone-icon-11.png',
        '/assets/stone-empty-icon.png'
      ],
      state: 'closed',
      result_text: null,
      popup: null
    };
  },
  mounted(){

    // this.init();
  },
  watch: {
    preload: function(value){

      if(value === false){
        this.$nextTick(function(){
          this.init();
        });
      }

    }
  },
  methods: {
    init(){
      let self = this;
      let node = self.$el;
      let result = self.appData.gameState.progress;
      let stoneIconNodes = Array.from(node.querySelectorAll('.outro--stone--icon'));

      console.log(result, stoneIconNodes);
      for(let i in stoneIconNodes){

        let relStaff = parseInt(stoneIconNodes[i].getAttribute('data-rel-staff'));

        console.log(relStaff, result);

        if(result.indexOf(relStaff) !== -1){
          stoneIconNodes[i].classList.add('active');
        }
      }

      let arcIconNodes = Array.from(node.querySelectorAll('.outro--arc--icon'));
      for(let i in arcIconNodes){

        let relStaff = parseInt(arcIconNodes[i].getAttribute('data-rel-staff'));

        if(result.indexOf(relStaff) !== -1){
          arcIconNodes[i].classList.add('active');
        }
      }

      console.log('inited');

    },
    showAbout(){

      this.popup = 'about';
    },
    resetGame(){

      let self = this;

      self.$parent.startNewGame();
    },
    openDoor(){

      let self = this;
      let gameResult = self.appData.gameState.progress;

      if(gameResult.length < 11){

        self.result_text = '<p>Це була крута битва! Але двері Олімпу відчиняються тільки для найсильніших. Спробуй поборотися ще раз і зібрати всі чарівні артефакти!</p>';
        self.state = 'preopened';
      }

      if(gameResult.length === 11){

        self.result_text = '<p>Про твою перемогу складатимуть легенди: тобі вдалося зібрати всі чарівні артефакти і стати богом! Міфи більше не владні над тобою! Тепер, коли ти знаєш істину, поділися нею з усіма!</p>';
        self.state = 'opened';
      }

    },
    closePopup(){

      this.popup = null;
    },
    share(type){
      let url = '';
      if(type === 'fb'){
        url = '//www.facebook.com/sharer/sharer.php?u=' + window.location.href;
      }
      if(type === 'telegram'){
        url = 'https://telegram.me/share/url?url=' + window.location.href;
      }
      if(type === 'viber'){
        url = 'viber://forward?text=' + window.location.href;
      }

      if(url){
        window.open(
            url,
            '_blank',
            'scrollbars=0,resizable=1,menubar=0,left=' + (window.innerWidth/2-225) +',top=0,width=550,height=440,toolbar=0,status=0'
        );
      }
    }
  }
}
</script>