<template>
  <div class="wrapper" style="z-index: 3">
    <div class="bg">
      <img src="/assets/app-bg.jpg">
    </div>
    <div class="preloader">
      <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="48.9619" cy="48.962" rx="15" ry="36" transform="rotate(-45 48.9619 48.962)" stroke="#F808FF" stroke-width="5"/>
        <ellipse cx="48.9618" cy="48.9619" rx="22" ry="43" transform="rotate(45 48.9618 48.9619)" stroke="#3EFDF0" stroke-width="3"/>
        <ellipse cx="48.9618" cy="48.9619" rx="15" ry="36" transform="rotate(45 48.9618 48.9619)" stroke="#3EFDF0" stroke-width="5"/>
        <ellipse cx="48.9619" cy="48.962" rx="22" ry="43" transform="rotate(-45 48.9619 48.962)" stroke="#F808FF" stroke-width="3"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default{
  name: 'preloader',
  props: ['assets'],
  data(){
    return {
      preloaded: [],
    };
  },
  mounted(){

    this.init();
  },
  methods: {

    init(){

      let self = this;

      for(let asset of self.assets){

        let img = document.createElement('img');

        img.addEventListener('load', function(){

          self.preloaded.push(asset);

          if(self.preloaded.length == self.assets.length){

            setTimeout(function(){

              self.$parent.preload = false;

            }, 200)
          }
        });

        img.addEventListener('error', function(){

          self.preloaded.push(asset);

          if(self.preloaded.length == self.assets.length){

            setTimeout(function(){

              self.$parent.preload = false;

            }, 200)
          }

        });

        img.src = asset;
      }
    }
  }
}
</script>